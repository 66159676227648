import { useState, useCallback, useEffect } from 'react'
import { MapContainer, TileLayer, CircleMarker, Popup } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import Airtable from 'airtable'

import './App.css'

const airtableBase = new Airtable({ apiKey: 'keyGbW9ItT7ypFdr4' }).base('appCKqPvrxJ9uJm4v')

function App() {
    const [markers, setMarkers] = useState<any[]>([])

    const [zoom, setZoom] = useState(12)
    const [position, setPosition] = useState<[number, number]>([51.479321, 11.960951])
    const [showModal, setModalState] = useState(false)

    const setDefault = useCallback(() => {
        setZoom(13)
        setPosition([51.479321, 11.960951])
    }, [])

    useEffect(() => {
        airtableBase('Displays')
            .select()
            .all()
            .then((result) => {
                const displays = []

                for (const entry of result) {
                    displays.push(entry.fields)
                }

                setMarkers(displays)
                setDefault()
            })
    }, [])

    const selectLocation = useCallback(
        (index: number) => {
            const entry = markers[index]

            setPosition([entry.Laengengrad, entry.Breitengrad])
            setZoom(20)
        },
        [markers]
    )

    const setModal = useCallback(() => {
        const newState = !showModal
        const modal = document.getElementById('main-container')

        if (modal) {
            modal.classList.toggle('blur-container')
        }

        setModalState(newState)

        if (newState === false) {
            setDefault()
        }
    }, [showModal, setDefault])

    const renderMap = useCallback(() => {
        return (
            <MapContainer
                className='markercluster-map'
                maxZoom={18}
                center={position}
                zoom={zoom}
                style={{ height: '100vh' }}
                key={Math.random()}
            >
                <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />

                <MarkerClusterGroup>
                    {markers.map((entry) => {
                        return (
                            <CircleMarker
                                stroke={true}
                                bubblingMouseEvents={true}
                                center={[entry.Laengengrad, entry.Breitengrad]}
                                key={entry.Name}
                            >
                                <Popup autoClose={true} keepInView={true} closeButton={true} zoomAnimation={true}>
                                    <h4>{entry.Name}</h4>
                                    <p className='description'>{entry.Standord}</p>
                                    <p className='description'>{entry.Beschreibung}</p>
                                    <p>
                                        <strong>Auflösung:</strong> {entry.Aufloesung}
                                    </p>
                                    <p>
                                        <strong>TKP:</strong> {entry.TKP}
                                    </p>
                                    <p>
                                        <strong>Preis / Woche:</strong> {entry.PreisProWoche}
                                    </p>
                                    {entry.Bild && entry.Bild[0].url ? (
                                        <div>
                                            <img className='display-img' alt={entry.Name} src={entry.Bild[0].url} />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </Popup>
                            </CircleMarker>
                        )
                    })}
                </MarkerClusterGroup>
            </MapContainer>
        )
    }, [position, zoom, markers])

    return (
        <div className='App'>
            <div id='modal' className={'modal' + (showModal ? ' active' : ' ')}>
                <h2 style={{ fontWeight: 300 }}>Sie besitzen eine Werbefläche?</h2>
                <p>
                    Veröffentlichen Sie jetzt kostenlos Ihre Werbefläche(n) auf unserer Webseite und profitieren Sie von unserem
                    Werbenetzwerk.
                </p>
                <p>
                    Als Werbenetzwerk können wir deutschlandweite Werbekampagnen schalten und so ist es uns möglich auch an die große
                    Unternehmen zu gelangen.
                </p>
                <p>
                    <strong>15% Provision bei einer Vermietung Ihrer Werbefläche durch uns!</strong>
                </p>
                <p>
                    <strong>Keine monatlichen kosten!</strong>
                </p>
                <p>
                    Bitte senden Sie eine formlose Mail an{' '}
                    <a href='mailto:info@werbeoffensive.com?subject=Neue Werbefläche&body=Hallo,%0D%0Aich möchte meine Werbefläche bei Ihnen hinterlegen.%0D%0ADaten zur Werbefläche:%0D%0A%0D%0AStraße, ggf. Hausnr.:%0D%0AGPS Geo Daten:%0D%0AAuflösung:%0D%0AGröße der Werbefläche:%0D%0APreis pro Woche:%0D%0ATKP:%0D%0A%0D%0A%0D%0ABitte senden Sie uns im Anhang auch ein passendes Bild Ihrer Werbefläche zu.'>
                        Hier klicken: info@werbeoffensive.com
                    </a>{' '}
                    mit folgenden Daten:
                </p>
                <ul>
                    <li>Genauer Standort der Werbefläche (Straße, Hausnummer)</li>
                    <li>GPS Geo Daten</li>
                    <li>Bevorzugter Name für die Anzeige auf dieser Website</li>
                    <li>Auflösung</li>
                    <li>Größe der Werbefläche</li>
                    <li>Foto der Werbefläche (bitte im Anhang senden)</li>
                    <li>Kontaktinformation für Anfragen</li>
                    <li>Ihre Website (falls vorhanden)</li>
                </ul>

                <br />
                <p>
                    <strong>Premium Tipp:</strong>
                </p>
                <p>
                    Sie möchten Ihren Kunden einfach weitere Standorte anbieten, ohne selbst weitere Werbeflächen aufzustellen? Mit uns ist
                    das möglich. Die meisten Werbeflächen-Betreiber entscheiden sich daher für eine preiswerte Mitgliedschaft in unserem
                    exklusiven Werbenetzwerk. Darüber erhalten Sie Zugang zu unserer Management-Software, mit welcher Sie direkten Zugriff
                    auf die vollautomatische Ausspielung auf allen verfügbaren Werbeflächen erhalten. Informieren Sie sich unverbindlich
                    unter: 0345 120 4178.
                </p>

                <br />
                <a href='/' onClick={() => setModal()}>
                    Schließen
                </a>
            </div>
            <div className='header'>
                <div className='column'>
                    <h3>
                        DOOH Werbeflächen in Deutschland <br /> <small>Deutschlandweite Kampagnen günstig buchen</small>
                    </h3>
                </div>
            </div>
            <div id='main-container' className='flex-container'>
                <div className='flex-left'>{renderMap()}</div>
                <div className='flex-right'>
                    <h1>Übersicht</h1>
                    <div className='add-new-btn' onClick={() => setModal()}>
                        Werbefläche kostenlos hinzufügen
                    </div>
                    <br />
                    <p>
                        <u>
                            Halle (Saale)
                            <span className='map-link' onClick={() => setDefault()}>
                                &nbsp;<strong>Alle {markers.length} Werbeflächen anzeigen</strong>
                            </span>
                        </u>
                    </p>

                    {markers.map((entry, index) => {
                        return (
                            <p key={Math.random()} className='map-link' onClick={() => selectLocation(index)}>
                                {entry.Name}
                            </p>
                        )
                    })}
                </div>
            </div>
            <div className='footer'>
                Diese Website ist ein kostenloser Service von&nbsp;
                <a target='_blank' rel='noreferrer' href='https://www.werbeoffensive.com'>
                    www.werbeoffensive.com.
                </a>
                <br />
                <br /> <strong>Impressum:</strong> <br />
                GOD Advertising GmbH <br />
                Birkunger Str. 51, D-37351 Dingelstädt
                <br />
                Tel.: 0345 120 4178
                <br />
                Handelsregister: HRB 512743 <br />
                Geschäftsführer: Orlando Fröhlich
            </div>
        </div>
    )
}

export default App
